<template>
  <v-card :loading="loadingTasks">
    <v-card-title class="headline grey lighten-2">
      Rechnung erstellen
    </v-card-title>

    <v-card-text>
      <v-select
        v-model="selectedTasks"
        :items="tasks"
        label="Tätigkeiten in Rechnung aufnehmen"
        multiple
        persistent-hint
        :hint="'Insgesamt: ' + formatEarnings(totalEarnings) + ' €'"
        :item-text="
          item => item.name + ': ' + formatEarnings(item.earnings) + ' €'
        "
        item-value="id"
        chips
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggleTasks">
            <v-list-item-action>
              <v-icon>
                {{
                  selectedTasks.length === tasks.length
                    ? "check_box"
                    : "check_box_outline_blank"
                }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Alle auswählen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
      <v-checkbox
          v-model="includeDayDescriptions"
          label="Tageszusammenfassungen in Rechnung aufnehmen (Experimentell)"
      ></v-checkbox>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="user.timer_tokens"
        color="primary"
        text
        @click="createInvoice(customer)"
        :loading="creatingInvoice !== null"
      >
        Rechnung erstellen
      </v-btn>
      <v-btn
        v-if="!user.timer_tokens"
        color="primary"
        text
        @click="tasksSelectionDone = true"
        :loading="creatingInvoice !== null"
      >
        Weiter
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  computed: {
    totalEarnings() {
      const tasks = this.tasks.filter(t =>
        this.selectedTasks.some(st => t.id == st)
      );
      return parseFloat(
        tasks.reduce(function(a, b) {
          return a + b["earnings"];
        }, 0)
      );
    },
    user() {
      return this.$store.getters["profile/me"];
    },
    tasks() {
      if (!this.customer) {
        return [];
      }
      return this.$store.getters["tasks/list"].filter(
        t => t.customer_id == this.customer.id
      );
    }
  },
  data() {
    return {
      selectedTasks: [],
      includeDayDescriptions: false,
      allTasksSelected: true,
      tasksSelectionDone: false,
      loadingTasks: false,
      creatingInvoice: null
    };
  },
  watch: {
    customer: {
      deep: true,
      immediate: true,
      handler(customer) {
        if (customer) {
          this.loadTasks();
        }
      }
    },
    tasks: {
      handler(tasks) {
        if (!tasks) {
          return;
        }
        this.selectedTasks = tasks.map(t => t.id);
        this.tasksSelectionDone = false;
      },
      deep: true
    }
  },
  methods: {
    loadTasks() {
      if (!this.customer) {
        return;
      }
      this.loadingTasks = true;
      this.$store
        .dispatch("tasks/list", {
          page: { size: 100 },
          filter: {
            customer_id: this.customer.id,
            has_duration: true,
            with_hours: true
          }
        })
        .then(() => {
          this.loadingTasks = false;
        });
    },
    createInvoice(customer) {
      this.creatingInvoice = customer.id;
      this.$store
        .dispatch("invoices/add", {
          type: "invoices",
          customer_id: customer.id,
          tasks: this.selectedTasks,
          include_day_descriptions: this.includeDayDescriptions
        })
        .then(() => {
          this.creatingInvoice = null;
          const invoice = this.$store.getters["invoices/invoice"];
          if (invoice.lx_id) {
            this.openUrl(
              "https://app.lexoffice.de/permalink/invoices/view/" +
                invoice.lx_id
            );
            this.$emit("success");
          } else {
            this.$swal.fire({
              title: "Synchronisierung fehlgeschlagen!",
              html: `Bitte überprüfen Sie Ihren Lexoffice-Schlüssel und stellen Sie sicher, dass für den ausgewählten Kunden eine Rechnungsadresse hinterlegt wurde.`,
              icon: "error",
              confirmButtonText: "Okay"
            });
            this.$emit("success");
          }
        })
        .catch(() => {
          this.creatingInvoice = null;
          this.$store.dispatch(
            "alerts/error",
            "Rechnung konnte nicht erstellt werden."
          );
          this.$emit("error");
        });
    },
    toggleTasks() {
      this.$nextTick(() => {
        if (this.selectedTasks.length === this.tasks.length) {
          this.selectedTasks = [];
        } else {
          this.selectedTasks = this.tasks.slice();
        }
      });
    }
  }
};
</script>
